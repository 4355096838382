import React from 'react'
import styled from 'styled-components'

// import media from '../helpers/media'

const ClickableArea = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 500ms ease;
  background-color: ${props =>
    props.visible ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0)'};
  ${props => !props.visible && 'pointer-events: none;'};
  z-index: 20;
  /* backdrop-filter: ${props => (props.visible ? 'blur(8px)' : 'none')}; */
`

const Wrapper = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  position: absolute;
  top: 20px;
  left: 50%;
  transition: transform 500ms cubic-bezier(0.17, 0.67, 0.3, 1.15);
  will-change: transform;
  transform: ${props =>
    props.visible
      ? 'translate(-50%)'
      : 'translate(-50%, calc( -100% - 80px ) )'};
  background: rgba(255, 255, 255, 0.8);
`

class Voucher extends React.Component {
  render() {
    const { visible, onClose } = this.props
    return (
      <ClickableArea visible={visible} onClick={onClose}>
        <Wrapper
          visible={visible}
          onClick={e => {
            e.stopPropagation()
            alert()
          }}
        ></Wrapper>
      </ClickableArea>
    )
  }
}

export default Voucher
